/* @jsx jsx */
import React, { useState } from 'react';
import { jsx, css } from '@emotion/core';
import { Field, ErrorMessage, withFormik, FormikProps } from 'formik';
import {
  defaultLeadSchema,
  loansTypeOptions,
  debtAmountOptions,
} from '../utils/schema';
import ButtonOptions from './ButtonOptions';

const formWrapCSS = css`
  padding: 15px 120px;
  h2 {
    color: #000;
    font-weight: 500;
    font-size: 20px;
  }

  input {
    display: block;
    padding: 10px;
    border: 1px solid #bcbcbc;
    width: 100%;
    font-size: 18px;
    border-radius: 6px;
    margin-bottom: 15px;
  }

  button {
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: #44e08d;
    padding: 10px 30px;
    border: 0;
    color: #fff;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 80px;
  }

  @media only screen and (max-width: 640px) {
    padding: 20px 40px;
    h2 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 10px;
  }
`;

const errorCSS = css`
  color: red;
`;

export type LeadFormValues = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  state: string;
  loans_type:
    | 'Federal Student Loan Debt'
    | 'Private Student Loan Debt'
    | 'Federal & Private Student Loan Debt';
  debt_amount: string;
};

interface ErrorMap {
  [key: string]: string;
}

type Props = {
  onSubmit: (values: LeadFormValues) => Promise<ErrorMap | any>;
  initialValues?: LeadFormValues;
};

interface StepProps {
  values?: any;
  handleChange?: any;
  nextStep?: any;
  prevStep?: any;
  isSubmitting?: boolean;
}

const StepOne: React.FC<StepProps> = ({ nextStep }) => (
  <div>
    <h2>What type of student loan debt do you have?</h2>
    <Field
      component={ButtonOptions}
      afterClick={nextStep}
      name="loans_type"
      data={loansTypeOptions}
    />
  </div>
);

const StepTwo: React.FC<StepProps> = ({ nextStep }) => (
  <div>
    <h2>Estimate your Student Loan Debt:</h2>
    <Field
      component={ButtonOptions}
      afterClick={nextStep}
      name="debt_amount"
      data={debtAmountOptions}
    />
  </div>
);

const StepThree: React.FC<StepProps> = ({ isSubmitting }) => (
  <div>
    <h2>Please enter your info:</h2>
    <Field type="text" name="first_name" placeholder="First Name" />
    <ErrorMessage name="first_name" component="div" css={errorCSS} />
    <Field type="text" name="last_name" placeholder="Last Name" />
    <ErrorMessage name="last_name" component="div" css={errorCSS} />
    <Field type="text" name="email" placeholder="Email" />
    <ErrorMessage name="email" component="div" css={errorCSS} />
    <Field type="text" name="phone" placeholder="Phone" />
    <ErrorMessage name="phone" component="div" css={errorCSS} />
    <button type="submit">{isSubmitting ? 'Sending...' : 'Submit'}</button>
  </div>
);

const C: React.FC<Props & FormikProps<LeadFormValues> & any> = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;
  const [step, setStep] = useState(1);
  const nextStep = () => setStep(prevStep => prevStep + 1);

  const renderStep = (props: StepProps) => {
    const stepMap = {
      1: StepOne,
      2: StepTwo,
      3: StepThree,
    } as { [key: number]: any };
    const Step = stepMap[step];

    return <Step {...props} />;
  };

  return (
    <form css={formWrapCSS} onSubmit={handleSubmit}>
      {renderStep({ nextStep: () => nextStep(), isSubmitting })}
    </form>
  );
};

const LeadWizardForm = withFormik<Props, LeadFormValues>({
  validationSchema: defaultLeadSchema,
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: props => props.initialValues as any,
  handleSubmit: async (values, { props, setErrors, setSubmitting }) => {
    const errors = await props.onSubmit(values);
    setSubmitting(false);
    if (errors) {
      setErrors(errors);
    }
  },
})(C);

export default LeadWizardForm;
