/** @jsx jsx */

import * as React from 'react';
import { FieldProps } from 'formik';
import { jsx, css } from '@emotion/core';

const buttonOptionsCSS = css`
  margin-bottom: 10px;

  button {
    font-size: 20px;
    line-height: 1.7em;

    @media only screen and (max-width: 640px) {
      font-size: 18px;
      white-space: nowrap;
    }

    @media only screen and (max-width: 480px) {
      font-size: 15px;
    }
  }
`;
interface ButtonOptionsProps {
  label?: string;
  data?: { label: string; value: string }[];
  afterClick?: () => any;
}

type Props = ButtonOptionsProps & FieldProps;
const ButtonOptions: React.FC<Props> = props => {
  const {
    field: { onChange, ...field },
    form: { touched, errors, setFieldValue },
    label,
    data = [],
    afterClick,
    ...rest
  } = props;

  // handle this somewhere? :)
  const errorMsg = touched[field.name] && errors[field.name];

  return (
    <div>
      {data.map(({ label: optionLabel, value }) => (
        <div css={buttonOptionsCSS} key={`${value}-holder`}>
          <button
            key={value}
            onClick={() => {
              setFieldValue(field.name, value);
              afterClick && afterClick();
            }}
          >
            {optionLabel}
          </button>
        </div>
      ))}
      <input name={field.name} value="" type="hidden" />
    </div>
  );
};

export default ButtonOptions;
